import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Footer from "./Footer";
import SignIn from './SignIn';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function RenewSubscription() {
    const classes = useStyles();
    const location = useLocation();
    const [extensioncode, setExtensioncode] = useState('')
    const [id, setId] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [city, setCity] = useState('');
    const [standard, setStandard] = useState('');
    const [avatar, setAvatar] = useState('');
    const [subscribed, setSubscribed] = useState('');
    let history = useHistory();
    useEffect(() => {
      if (location.state !== undefined) {
        setId(location.state.id)
        setPassword(location.state.password)
        setFirstname(location.state.firstname)
        setLastname(location.state.lastname)
        setCity(location.state.city)
        setStandard(location.state.standard)
        setAvatar(location.state.avatar)
        setSubscribed(location.state.subscribed)      
      }
    }, [location]);
  
    if (!id) {
      return <SignIn />
    }
     

     const renewSubscription = async (account) => {
        const res = await fetch('/api/v2/accounts/renew/', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(account),
        })
        const data = await res.json()
        alert(data["message"])
        if (res.status === 200) {
            history.push({
                pathname: "/accounts/home/",
                state: {
                  id: data["id"],
                  password: data["password"],
                  firstname:data["firstname"],
                  lastname: data["lastname"],
                  city: data["city"],
                  standard: data["standard"],
                  avatar: data["avatar"],
                  subscribed: data["subscribed"]
                }
            })
        }
      }
     const onSubmit = (e) => {
        e.preventDefault()
        if (!extensioncode) {
            alert('Please enter extension code received after making payment.')
            return
          }
          renewSubscription({ id, extensioncode })
          setExtensioncode('')
      }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                Renew your subscription
                </Typography>
                <br></br>
                <p>Enter the extension code received after payment</p>
                <form className={classes.form} noValidate onSubmit={onSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="extensioncode"
                        label="Extension Code"
                        type="password"
                        id="extensioncode"
                        autoComplete="current-password"
                        value={extensioncode}
                        onChange={(e) => setExtensioncode(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Confirm
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/accounts/signin/" variant="body2">
                            Change Number
                            </Link>
                        </Grid>
                        <Grid item>
                        <Link to ={{
    pathname: "/accounts/pay", 
    state: { 
      id: id,
      password: password
    }
   }} 
                            variant="body2">
                                {"Request Extension Code"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
                <Footer/>
            </div>
        </Container>
    );
}