import React from 'react';
import "./SignIn.css";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Footer from "./Footer";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  // document.getElementById('zmmtg-root').style.display = 'none'
  const [phonenumber, setPhonenumber] = useState('')
  const [password, setPassword] = useState('')
  
  let history = useHistory();

  const signin = async (account) => {
    const res = await fetch('/api/v2/accounts/login/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(account),
    })
    const data = await res.json()    
    //console.log(res.status)
    if (res.status === 200) {
      if(data["subscribed"] == "True") {
        history.push({
          pathname: "/accounts/home/",
          state: {
            id: data["id"],
            password: data["password"],
            firstname:data["firstname"],
            lastname: data["lastname"],
            city: data["city"],
            standard: data["standard"],
            avatar: data["avatar"],
            subscribed: data["subscribed"]
          }
        })
      } else {
        history.push({
          pathname: "/accounts/renew/",
          state: {
            id: data["id"],
            password: data["password"],
            firstname:data["firstname"],
            lastname: data["lastname"],
            city: data["city"],
            standard: data["standard"],
            avatar: data["avatar"],
            subscribed: data["subscribed"]
          }
        })
      }
    }
    else
    {
      alert('Login failed. Please retry!')
      setPhonenumber('')
      setPassword('')
    }
  }
  const onSubmit = (e) => {
    e.preventDefault()
    if (!phonenumber) {
      alert('Please enter 10 digit phone number')
      setPhonenumber('')
      setPassword('')
      return
    }
    if (!password) {
      alert('Please enter your password')
      setPhonenumber('')
      setPassword('')
      return
    }
    if (typeof phonenumber !== "undefined") {  
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(phonenumber)) {
        alert('Please enter 10 digit phone number')
        setPhonenumber('')
        setPassword('')
        return
      }else if(phonenumber.length !== 10){
        alert('Please enter 10 digit phone number')
        setPhonenumber('')
        setPassword('')
        return
      }
    }
    
    signin({ phonenumber, password })
   
    setPhonenumber('')
    setPassword('')
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phonenumber"
            label="Phone Number"
            name="phonenumber"
            autoComplete="phonenumber"
            autoFocus
            value={phonenumber}
            onChange={(e) => setPhonenumber(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/accounts/password/reset/" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/accounts/signup/" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
        <Footer />
      </div>
    </Container>
  );
}