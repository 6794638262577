import React from 'react';
import "./Home.css";
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SignIn from './SignIn';
import Footer from './Footer';
import TweetBox from './TweetBox';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from 'react'
import Feed from "./Feed"
const width= window.screen.width;
const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function User() {
  const classes = useStyles();
  const location = useLocation();
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')
  const [profile, setProfile] = useState('');
  
  useEffect(() => {
    if (location.state !== undefined) {
      setId(location.state.id)
      setPassword(location.state.password)
      setProfile(location.state.profile)  
    }
  }, [location]);

  if (!id) {
    return <SignIn />
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main className="home" style={{width:width,height:'100%'}} >
        <Container className={classes.cardGrid} maxWidth="md" padding-top="5px">
          <TweetBox
            id={id}
            password={password}
            profile={profile}
          />
          
          <Feed
            id={profile}
            password={password}
            profile={profile}
          />
          <Footer />
        </Container>
      </main>
    </React.Fragment>
  );
}