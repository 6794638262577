import React, { useState, useEffect } from "react";
import Post from "./Post";
import "./Feed.css";
import FlipMove from "react-flip-move";

function Feed({ id, password, standard, subscribed }) {
  const [posts, setPosts] = useState([]);
  const [searchtext, setSearchtext] = useState('');
  const BarStyling = { width: "100%", background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.87)", padding: "0.5rem", marginBottom: "1.5rem" };

  const gettimelinefeed = async (account) => {
    const res = await fetch('/api/v2/accounts/home/feed/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(account),
    })
    const data = await res.json()
    
    if (res.status === 200) {
      setPosts(data["posts"])
    }
  }
  
  useEffect(() => {
    gettimelinefeed({ id, password })
  }, [id,password]);
  const onSubmit = (e) => {
    e.preventDefault()
    if (!searchtext) {
      gettimelinefeed({ id, password })
      return
    }
    //const tags = searchtext.toLowerCase().split(" ")[0];
    //console.log(id)
    //console.log(password)
    gettimelinefeed({ id, password })
    setSearchtext('')
  }
  return (
    <div className="feed">
      <form noValidate onSubmit={onSubmit}>
        <input
          style={BarStyling}
          key="searchtext"
          value={searchtext}
          placeholder={"type single keyword to search all matching posts"}
          onChange={(e) => setSearchtext(e.target.value)}
        />
      </form>
      <FlipMove>
        {posts.map((post) => (
           post.standard!== undefined && post.standard!== 'masterclass' &&
          <Post
            uid={id}
            password={password}
            verified={subscribed}
            key={post.id}
            postid={post.id}
            message={post.message}
            url={post.url}
            parent={post.parent}
            author={post.author}
            created={post.created}
            firstname={post.firstname}
            lastname={post.lastname}
            city={post.city}
            standard={standard}
            avatar={post.avatar}
            subscribed={post.subscribed === "True"}
            tlikes={post.likes}
            tdislikes={post.dislikes}
            tcomments={post.comments}
            tdownloads={post.downloads}
            mypost={post.author === id}
          />
        ))}
      </FlipMove>
    </div>
  );
}

export default Feed;