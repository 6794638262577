import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Footer from "./Footer";
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ActivateAccount() {
    const classes = useStyles();
    const [id, setId] = useState('')
    const [passcode, setPasscode] = useState('')
    let history= useHistory();
    const location = useLocation();

  useEffect(() => {
    if(location.state !== undefined)
    {setId(location.state.id);
    }
  }, [location]);
     

     const activateAccount = async (account) => {
        const res = await fetch('/api/v2/accounts/activate/', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(account),
        })
        const data = await res.json()
        
        if (res.status === 200) {
            alert(data["message"])
            history.push({
              pathname: "/accounts/password/change/",
              state: { id: id,
              passcode: passcode}
            })
        }
      }
     const onSubmit = (e) => {
        e.preventDefault()
        if (!passcode) {
            alert('Please enter passcode received over sms.')
            return
          }
          activateAccount({ id, passcode })
          setPasscode('')
      }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                Verify your identify
                </Typography>
                <br></br>
                <p>Enter the passcode received over Email / SMS to your phone</p>
                <form className={classes.form} noValidate onSubmit={onSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="passcode"
                        label="Passcode"
                        type="password"
                        id="passcode"
                        autoComplete="current-password"
                        value={passcode}
                        onChange={(e) => setPasscode(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Confirm
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/accounts/signup/" variant="body2">
                            Change Number
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/accounts/password/reset/" variant="body2">
                                {"Request New Code"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
                <Footer/>
            </div>
        </Container>
    );
}