import React, { useEffect, useState } from "react"
import SignIn from './SignIn';
import "./style.css";
const PaytmChecksum = require('paytmchecksum');
const https = require('https');


export function PaytmButton({ id, extensioncode,password }) {
    let orderId = extensioncode;
    const [paymentData, setPaymentData] = useState({
        token: "",
        order: "",
        mid: "",
        amount: ""
    });
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        initialize();
    }, []);
    const extendSubscription = async (account) => {
        const res = await fetch('/api/v2/accounts/extend/', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(account),
        })
        const data = await res.json()
        if (res.status === 200) {
          console.log('Extension code generated successfully.')
            }
      }
    const initialize = () => {
        //console.log(orderId);
        extendSubscription({id,password,extensioncode});
        // Sandbox Credentials
        let mid = "WORKFO03364328868961"; // Merchant ID
        let mkey = "b24@exKr2HNOSfFS"; // Merhcant Key
        var paytmParams = {};

        paytmParams.body = {
            "requestType": "Payment",
            "mid": mid,
            "websiteName": "DEFAULT",
            "orderId": orderId,
            "callbackUrl": 'https://shikshamitr.in/payment/success?exn_code='+orderId,//+"&PWD="+password+"&ORDER_ID="+orderId,
            "txnAmount": {
                "value": "999.00", //amount #599
                "currency": "INR",
            },
            "userInfo": {
                "custId": id,
            }
        };

        PaytmChecksum.generateSignature(JSON.stringify(paytmParams.body), mkey).then(function (checksum) {
            //console.log(checksum);
            paytmParams.head = {
                "signature": checksum
            };

            var post_data = JSON.stringify(paytmParams);

            var options = {
                /* for Staging */
                hostname: 'securegw.paytm.in',

                /* for Production */
                // hostname: 'securegw.paytm.in',

                port: 443,
                path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Content-Length': post_data.length
                }
            };

            var response = "";
            var post_req = https.request(options, function (post_res) {
                post_res.on('data', function (chunk) {
                    response += chunk;
                });
                post_res.on('end', function () {
                    //  console.log('Response: ', response);
                    // extendSubscription({ id,password, extensioncode });    
                    // res.json({data: JSON.parse(response), orderId: orderId, mid: mid, amount: amount});
                    setPaymentData({
                        ...paymentData,
                        token: JSON.parse(response).body.txnToken,
                        order: orderId,
                        mid: mid,
                        amount: "999.00" // amount
                    })
                });
            });

            post_req.write(post_data);
            post_req.end();
        });
    }
    const makePayment = () => {
        setLoading(true);
        var config = {
            "root": "",
            "style": {
                "bodyBackgroundColor": "#fafafb",
                "bodyColor": "",
                "themeBackgroundColor": "#0FB8C9",
                "themeColor": "#ffffff",
                "headerBackgroundColor": "#284055",
                "headerColor": "#ffffff",
                "errorColor": "",
                "successColor": "",
                "card": {
                    "padding": "",
                    "backgroundColor": ""
                }
            },
            "data": {
                "orderId": paymentData.order,
                "token": paymentData.token,
                "tokenType": "TXN_TOKEN",
                "amount": paymentData.amount /* update amount */
            },
            "payMode": {
                "labels": {},
                "filter": {
                    "exclude": []
                },
                "order": [
                    "CC",
                    "DC",
                    "NB",
                    "UPI",
                    "PPBL",
                    "PPI",
                    "BALANCE"
                ]
            },
            "website": "WEB",
            "flow": "DEFAULT",
            "merchant": {
                "mid": paymentData.mid,
                //"redirect": false
                "redirect": true, 
                "multipleWindowWebview":true 
            },
            "handler": {
                "transactionStatus": function transactionStatus(paymentStatus) {
                    //console.log("payment before=> ", paymentStatus);
                           setLoading(false);
                }, "notifyMerchant": function notifyMerchant(eventName, data) {
                    //console.log("Closed");
                    setLoading(false);
                }
            }
        };

        if (window.Paytm && window.Paytm.CheckoutJS) {
            // initialze configuration using init method
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                //console.log('Before JS Checkout invoke');
                // after successfully update configuration invoke checkoutjs
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                //console.log("Error => ", error);
            });
        }
    }
    if (!id) {
        return <SignIn />
    }
    return (
        <div>
            {
                loading ? (
                    <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />
                ) : (
                    <button onClick={makePayment}>Pay Now</button>
                )
            }
        </div>
    )
}