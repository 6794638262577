import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Footer from "./Footer";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [phonenumber, setPhonenumber] = useState('')
  
  let history = useHistory();

  const forgotPassword = async (account) => {
    const res = await fetch('/api/v2/accounts/password/reset/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(account),
    })
    const data = await res.json()
    
    if (res.status === 200) {
      alert(data["message"])
      history.push({
        pathname: "/accounts/activate/",
        state: { id: data["id"] }
      })
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!phonenumber) {
      alert('Please enter 10 digit phone number')
      return
    }

    forgotPassword({phonenumber})
    setPhonenumber('')
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Trouble Logging In ?
        </Typography>
        <br></br>
        <p>Enter your phone number, we will send you the passcode.</p>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phonenumber"
            label="Phone Number"
            name="phonenumber"
            autoComplete="phonenumber"
            autoFocus
            value={phonenumber}
            onChange={(e) => setPhonenumber(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send Passcode
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/accounts/login/" variant="body2">
                Back to Login
              </Link>
            </Grid>
            <Grid item>
              <Link to="/accounts/signup/" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
        <Footer/>
      </div>
    </Container>
  );
}