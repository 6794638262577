import React, { useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import SignIn from './SignIn';
import './LiveClass.css';

const LiveClass = () => {
  const location = useLocation();
  const standard = location.query.standard;
  const student = location.query.student;
  // This sample app has been updated to use Meeting SDK credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  var sdkKey = 'eaTDPNVFQBGkyeW7OJ9ANw'
  var sdkSecret = 'yMj64gU2TFw3k1MW5r2n1eLpDfAjUzr5'
  var role = 0
  var userEmail = 'tvamevnarayan@gmail.com';
  let meetingIdMap =new Map([
    ["i","4973154659"],
    ["ii","4973154659"],
    ["iii","4973154659"],
    ["iv","4973154659"],
    ["v","4973154659"],
    ["vi","4973154659"],
    ["vii","4973154659"],
    ["viii","4973154659"],
    ["ix","4973154659"],
    ["x","4973154659"],
    ["xi","4973154659"],
    ["xii","4973154659"],
    ["xiii","4973154659"]
  ]);
  let meetingPwMap =new Map([
    ["i","03iDVP"],
    ["ii","03iDVP"],
    ["iii","03iDVP"],
    ["iv","03iDVP"],
    ["v","03iDVP"],
    ["vi","03iDVP"],
    ["vii","03iDVP"],
    ["viii","03iDVP"],
    ["ix","03iDVP"],
    ["x","03iDVP"],
    ["xi","03iDVP"],
    ["xii","03iDVP"],
    ["xiii","03iDVP"]
  ]);
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  var leaveUrl = 'https://shikshamitr.in'

  

  useEffect(async () => {

    const { ZoomMtg } = await import("@zoomus/websdk");
    ZoomMtg.setZoomJSLib('https://source.zoom.us/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    
    ZoomMtg.generateSDKSignature({
      meetingNumber: meetingIdMap.get(standard),
      role: role,
      sdkKey: sdkKey,
      sdkSecret: sdkSecret,
      success: function (signature) {
        ZoomMtg.init({
          leaveUrl: leaveUrl,
          success: function (data) {
            ZoomMtg.join({
              meetingNumber: meetingIdMap.get(standard),
              signature: signature.result,
              sdkKey: sdkKey,
              userName: student,
              userEmail: userEmail,
              passWord: meetingPwMap.get(standard),
              tk: '',
              success: function () {
                //console.log("-- Joined --");
              },
              error: function (error) {
                console.log(error)

              }

            })
          },
          error: function (error) {
            console.log(error)
          }
        })
      },
      error: function (error) {
        console.log(error)
      }
    })

  }, []);

  if (!standard) {
    return <SignIn />  
  }
  return (<Fragment>
    <link type="text/css" rel="stylesheet" href="https://source.zoom.us/2.9.0/css/bootstrap.css"></link>
    <link type="text/css" rel="stylesheet" href="https://source.zoom.us/2.9.0/css/react-select.css"></link>
  </Fragment>);
}
export default LiveClass;