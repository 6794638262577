import React, { useState, useEffect } from "react";
import Person from "./Person";
import "./Feed.css";
import FlipMove from "react-flip-move";

function Classmates({ id, password, standard }) {
  const [classmates, setClassmates] = useState([]);
  const [searchtext, setSearchtext] = useState('');
  const BarStyling = { width: "100%", background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.87)", padding: "0.5rem", marginBottom: "1.5rem" };
  const classname="My Class";
  const getallclassmates = async (account) => {
    const res = await fetch('/api/v2/accounts/class/listall/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(account),
    })
    const data = await res.json()
    
    if (res.status === 200) {
      setClassmates(data["classmates"])
    }
  }

  useEffect(() => {
    getallclassmates({ id, password })
  }, [id,password]);
  const onSubmit = (e) => {
    e.preventDefault()
    if (!searchtext) {
        getallclassmates({ id, password })
      return
    }
    //const tags = searchtext.toLowerCase().split(" ")[0];
    getallclassmates({ id, password })
    setSearchtext('')
  }
  return (
    <div className="feed">
    
      <form noValidate onSubmit={onSubmit}>
        <input
          style={BarStyling}
          key="searchtext"
          value={searchtext}
          placeholder={"search classmates by name, phone or email address"}
          onChange={(e) => setSearchtext(e.target.value)}
        />
      </form>
      <h3>{classname}</h3>
      <FlipMove>
        {classmates.map((classmate) => (
          <Person
            uid={id}
            password={password}
            key={classmate.id}
            oth_id={classmate.id}
            firstname={classmate.firstname}
            lastname={classmate.lastname}
            city={classmate.city}
            standard={classmate.standard}
            avatar={classmate.avatar}
            subscribed={classmate.subscribed === "True"}
            myprofile={id === classmate.id}
            myclass= {true}
          />
        ))}
      </FlipMove>
    </div>
  );
}

export default Classmates;