import React, { useState } from "react";
import "./TweetBox.css";
import { Avatar, Button } from "@material-ui/core";
import Header from "./Header"
import Compressor from 'compressorjs';
import axios from 'axios';

import { useHistory } from 'react-router-dom';
function TweetBox({ id,
  password,
  firstname,
  lastname,
  city,
  standard,
  avatar,
  subscribed }) {
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  function isImage(url) {
    const filename = url.toLowerCase();
    return (filename.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }
  const [title, setTitle] = useState("Hi " + firstname + "!");
  let history = useHistory();
  async function upload(e) {
    const maxSize = 10485760;
    //console.log(e.target.files[0].size);
    if (!e.target.files[0]) {
      alert('Please upload a non-empty file.');
      return;
    }
    //console.log(e.target.files[0].size)
    if (e.target.files[0].size > maxSize) {
      alert('Please upload a file of size less than 10 MB');
      return;
    }
    var file = e.target.files[0];
    if (!isImage(e.target.files[0].name)) {
      const maxPDFSize = 2097152;
      if (e.target.files[0].size > maxPDFSize) {
        alert('Please upload a file of size less than 2 MB');
        return;
      }
      const data = new FormData();
      data.append('id', id);
      data.append('password', password);
      data.append('file', e.target.files[0]);
      //console.log(id)
      //console.log(password)
      const res = await fetch('/api/v2/accounts/file/create/', {
        method: 'POST',
        //  headers: {
        //    'Content-type': 'application/json',
        //  },
        body: data,
      })
      const out = await res.json()

      if (res.status === 200) {
        //console.log(out["message"])
        setUrl(out["url"])
      }
    }
    else {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 800,
        maxHeight: 600,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          const formData = new FormData();
          formData.append('id', id);
          formData.append('password', password);
          // The third parameter is required for server
          formData.append('file', result, result.name);
          // Send the compressed image file to server with XMLHttpRequest.
          axios.post('/api/v2/accounts/file/upload/', formData).then(function (response) {
            //console.log(response);
            if (response.status === 200) {
              setUrl(response.data["url"])
            }
          })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }
  }
  const addPost = async (post) => {

    const res = await fetch('/api/v2/accounts/post/create/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },

      body: JSON.stringify(post),
    })
    const data = await res.json()

    if (res.status === 200) {
      //console.log(data["message"])
      alert(data["message"])
    }
  }
  const sendTweet = (e) => {
    e.preventDefault();
    if (!message) {
      alert('Please enter some text')
      return
    }
    // CALL FOR ACTION BASED ON EVENT
    const author = id
    const authkey = password
    addPost({ message, url, author, authkey })
    setMessage("");
    setUrl("");
  };
  return (
    <div className="tweetBox">

      <Header id={id}
        password={password}
        firstname={firstname}
        lastname={lastname}
        city={city}
        standard={standard}
        avatar={avatar}
        subscribed={subscribed}
      />
      <h1>{title}</h1>
      <form>
        <div className="tweetBox__input">
          <Avatar src={avatar} />
          <input
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            placeholder="Have something to ask?"
            type="textbox"
          />
        </div>
        <div className="tweetBox__imageInput">
          <input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="tweetBox__imageInput"
            placeholder="Optional: Enter image, document or video link"
            type="text"
          />
        </div>
        <div className="tweetBox__imageInput">
          <input required type='file' onChange={e => upload(e)} />
        </div>
        <Button
          onClick={sendTweet}
          type="submit"
          className="tweetBox__tweetButton"
        >
          Post
        </Button>
      </form>
    </div>
  );
}

export default TweetBox;