import React, { forwardRef, useState, useEffect } from "react";
import { useReducer } from "react";
import "./Post.css";
import ReactPlayer from "react-player"
import { Avatar } from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import { saveAs } from 'file-saver';
import { Document, Page, pdfjs } from "react-pdf";
import { ReactTinyLink } from 'react-tiny-link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ClearIcon from '@material-ui/icons/Clear';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import FlipMove from "react-flip-move";
import DuoIcon from '@material-ui/icons/Duo';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HANDLE_LIKE = Symbol("HANDLE_LIKE");
const HANDLE_DISLIKE = Symbol("HANDLE_DISLIKE");
const HANDLE_COMMENT = Symbol("HANDLE_COMMENT");
const HANDLE_DOWNLOAD = Symbol("HANDLE_DOWNLOAD");
const HANDLE_DELETE = Symbol("HANDLE_DELETE");
const HANDLE_HIDE = Symbol("HANDLE_HIDE");
const width = window.screen.width * 0.68;

const Post = forwardRef(
  ({ uid, password,verified, key, postid, message, url, parent, author, created, firstname, lastname, city, standard, avatar, subscribed, tlikes, tdislikes, tcomments, tdownloads, mypost }, ref) => {
    const initialState = {
      id: postid,
      thumbups: tlikes,
      thumbdowns: tdislikes,
      comments: tcomments,
      downloads: tdownloads,
      active: null
    };
    const [posts, setPosts] = useState([]);
    const name = firstname + " " + lastname;
    const BarStyling = { width: "100%", background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.87)", padding: "0.5rem", marginBottom: "1.5rem" };
    const formStyling = { width: "100%" };
    const [comment, setComment] = useState('');
    const [toggleComment, setToggleComment] = useState(false);
    useEffect(() => {
      populateComments({ uid, password,id })
    }, [uid,password]);
    const populateComments = async (post) => {
      setToggleComment(!toggleComment)
      if (toggleComment) {
        const data = new FormData();
        data.append('uid', post.uid);
        data.append('password', post.password);
        data.append('parent', post.id);
        const res = await fetch('/api/v2/accounts/post/comments/', {
          method: 'POST',
          //headers: {
          //'Content-type': 'application/json',
          //},
          body: data,
        })
        const out = await res.json()

        if (res.status === 200) {
          //console.log(out["message"])
          //console.log(out["posts"])
          setPosts(out["posts"])
        }
      }
      else (
        setPosts([])
      )
    }
    const reducer = (state, action) => {
      const { id, thumbups, thumbdowns, comments, downloads, active } = state;
      const handleLike = async (post) => {
        const data = new FormData();
        data.append('uid', post.uid);
        data.append('password', post.password);
        data.append('pid', post.id);
        const res = await fetch('/api/v2/accounts/post/like/', {
          method: 'POST',
          //  headers: {
          //    'Content-type': 'application/json',
          //  },
          body: data,
        })
        const out = await res.json()

        if (res.status === 200) {
          //console.log(out["message"])
        }
      }
      const handleDislike = async (post) => {
        const data = new FormData();
        data.append('uid', post.uid);
        data.append('password', post.password);
        data.append('pid', post.id);
        const res = await fetch('/api/v2/accounts/post/dislike/', {
          method: 'POST',
          //  headers: {
          //    'Content-type': 'application/json',
          //  },
          body: data,
        })
        const out = await res.json()

        if (res.status === 200) {
          //console.log(out["message"])
        }
      }
      const handleComment = async (post) => {
        setToggleComment(!toggleComment)
        if (toggleComment) {
          const data = new FormData();
          data.append('uid', post.uid);
          data.append('password', post.password);
          data.append('parent', post.id);
          const res = await fetch('/api/v2/accounts/post/comments/', {
            method: 'POST',
            //headers: {
            //'Content-type': 'application/json',
            //},
            body: data,
          })
          const out = await res.json()

          if (res.status === 200) {
            //console.log(out["message"])
            //console.log(out["posts"])
            setPosts(out["posts"])
          }
        }
        else (
          setPosts([])
        )
      }
      const handleDownload = async (post) => {
        const data = new FormData();
        data.append('uid', post.uid);
        data.append('password', post.password);
        data.append('pid', post.id);
        data.append('downloads', post.downloads);
        const res = await fetch('/api/v2/accounts/post/download/', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: data,
        })
        const out = await res.json()

        if (res.status === 200) {
          //console.log(out["message"])
        }
      }
      const handleDelete = async (post) => {
        const data = new FormData();
        data.append('uid', post.uid);
        data.append('password', post.password);
        data.append('pid', post.id);
        //console.log(post.uid)
        //console.log(post.password)
        //console.log(post.id)
        const res = await fetch('/api/v2/accounts/post/delete/', {
          method: 'POST',
          //  headers: {
          //    'Content-type': 'application/json',
          //  },
          body: data,
        })
        const out = await res.json()

        if (res.status === 200) {
          alert(out["message"])
        }
      }
      const handleHide = async (post) => {
        const data = new FormData();
        data.append('uid', post.uid);
        data.append('password', post.password);
        data.append('pid', post.id);
        //console.log(post.uid)
        //console.log(post.password)
        //console.log(post.id)
        const res = await fetch('/api/v2/accounts/post/hide/', {
          method: 'POST',
          //  headers: {
          //    'Content-type': 'application/json',
          // },
          body: data,
        })
        const out = await res.json()

        if (res.status === 200) {
          alert(out["message"])
        }
      }

      let likes = state.thumbups - 1;
      let dislikes = state.thumbdowns - 1;
      switch (action.type) {
        case HANDLE_LIKE:
          likes = state.thumbups - 1 + 2;
          dislikes = (active === "dislike" ? state.thumbdowns - 1 : state.thumbdowns);
          handleLike({ uid, password, id }
          )
          return {
            ...state,
            id: state.id,
            thumbups: likes,
            thumbdowns: dislikes,
            comments: state.comments,
            downloads: state.downloads,
            active: "like"
          }
        case HANDLE_DISLIKE:
          dislikes = state.thumbdowns - 1 + 2;
          likes = (active === "like" ? state.thumbups - 1 : state.thumbups);
          handleDislike({ uid, password, id })
          return {
            ...state,
            id: state.id,
            thumbups: likes,
            thumbdowns: dislikes,
            comments: state.comments,
            downloads: state.downloads,
            active: "dislike"
          }
        case HANDLE_COMMENT:
          let comments = state.comments;
          handleComment({ uid, password, id })
          return {
            ...state,
            id: state.id,
            thumbups: state.thumbups,
            thumbdowns: state.thumbdowns,
            comments: comments,
            downloads: state.downloads,
            active: state.active
          }
        case HANDLE_DOWNLOAD:
          let downloads = state.downloads + 1;
          handleDownload({ uid, password, id })
          return {
            ...state,
            id: state.id,
            thumbups: state.thumbups,
            thumbdowns: state.thumbdowns,
            comments: state.comments,
            downloads: downloads,
            active: state.active
          }
        case HANDLE_DELETE:
          handleDelete({ uid, password, id })
          return {
            ...state,
            id: state.id,
            thumbups: state.thumbups,
            thumbdowns: state.thumbdowns,
            comments: state.comments,
            downloads: state.downloads,
            active: "none"
          }
        case HANDLE_HIDE:
          handleHide({ uid, password, id })
          return {
            ...state,
            id: state.id,
            thumbups: state.thumbups,
            thumbdowns: state.thumbdowns,
            comments: state.comments,
            downloads: state.downloads,
            active: "none"
          }

        default:
          return state;
      }
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const { id, thumbups, thumbdowns, comments, downloads, active } = state;
    const [youtube, setYoutube] = useState("https://www.youtube.com/embed/" + YouTubeGetID(url));
    const [pdf, setPDF] = useState(url);
    const [file, setFile] = useState({ url });
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
    function onLoadError(error) {
      console.log(error);
    }

    function onSourceError(error) {
      console.log(error);
    }

    function changePage(offset) {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
      changePage(-1);
    }

    function nextPage() {
      changePage(1);
    }

    function isImage(url) {
      if (isBlank(url)) {
        return false;
      }
      const filename = url.toLowerCase();
      return (filename.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }
    function isPDF(url) {
      if (isBlank(url)) {
        return false;
      }
      return (url.match(/\.(pdf)$/) != null);
    }
    function isYoutubeVideo(url) {
      if (isBlank(url)) {
        return false;
      }
      return (url.includes("youtube.com") || url.includes("youtu.be"));
    }
    function isSoundCloud(url) {
      if (isBlank(url)) {
        return false;
      }
      return (url.includes("soundcloud.com"));
    }
    function isZoomLink(url) {
      if (isBlank(url)) {
        return false;
      }
      return (url.includes("zoom.us"));
    }
    function isGoogleMeet(url) {
      if (isBlank(url)) {
        return false;
      }
      return (url.includes("meet.google.com"));
    }
    function YouTubeGetID(url) {
      if (isBlank(url)) {
        return false;
      }
      var ID = '';
      url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
      }
      else {
        ID = url;
      }
      return ID;
    }
    function isBlank(url) {
      if (url === null || url === "" || url == undefined)
        return true;
      return false;
    }
    function isWeblink(url) {
      if (!(isBlank(url) || isImage(url) || isPDF(url) || isYoutubeVideo(url) || isSoundCloud(url) || isZoomLink(url))) {
        return validURL(url);
      }
      return false;
    }
    function validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str);
    }
    function isNotFirst(pageNumber) {
      return pageNumber > 1
    }
    function isNotLast(pageNumber, numPages) {
      return pageNumber < numPages
    }
    const onSubmit = (e) => {
      e.preventDefault()
      postComment(uid, password, comment, id)
      setComment('')
    }
    async function postComment(uid, password, comment, parent) {
      const data = new FormData();
      data.append('uid', uid);
      data.append('password', password);
      data.append('message', comment);
      data.append('parent', parent);
      const res = await fetch('/api/v2/accounts/post/comment/', {
        method: 'POST',
        // headers: {
        //   'Content-Type': 'multipart/form-data'
        // },
        body: data
      })
      const out = await res.json()
      if (res.status === 200) {
        //console.log(out["message"])
      }
    }
    return (
      <div className="post" ref={ref}>
        <div className="post__avatar">
          <Avatar src={avatar} />
        </div>
        <div className="post__body">
          <div className="post__header">
            <div className="post__headerText">
              <h3>
                {name}
                <span className="post__headerSpecial">
                  {subscribed && <VerifiedUserIcon className="post__badge" />}
                  {" shared a new post"}
                </span>
                <IconButton className="post__icon" aria-label="hidedelete" onClick={() => mypost ? dispatch({ type: HANDLE_DELETE }) : dispatch({ type: HANDLE_HIDE })}>
                  <ClearIcon />
                </IconButton>
              </h3>

            </div>
            <div className="post__headerDescription">
              <p>{message}</p>
            </div>
          </div>
          {isImage(url) && <img src={url} width="100%" height="100%" alt=" " />}
          {isPDF(url) && <div>
            <Document
              file={pdf}
              options={{ workerSrc: "/pdf.worker.js" }}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onLoadError}
              onSourceError={onSourceError}
            >
              <Page pageNumber={pageNumber} width={width * 0.64} />
            </Document>
            <div>
              {isNotFirst(pageNumber) &&
                <NavigateBeforeIcon type="button" disabled={pageNumber <= 1} onClick={previousPage} />}
              {isNotLast(pageNumber, numPages) && <NavigateNextIcon
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              />}
            </div>
          </div>}
          {isYoutubeVideo(url) && <iframe
            width="100%"
            height="240"
            src={youtube}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />}
          {isSoundCloud(url) && <ReactPlayer
        url={url}
        width='100%'
      />}
      {isZoomLink(url) && <a href={url} target="_blank"><img src="/join.png" alt="Join now" width="100%" height="100%" href={url}/></a>}
      {isGoogleMeet(url) && <a href={url} target="_blank"><img src="/join.png" alt="Join now" width="100%" height="100%" href={url}/></a>}
          {isWeblink(url) && <ReactTinyLink
            cardSize="small"
            showGraphic={true}
            maxLine={2}
            minLine={1}
            width={width}
            height={100}
            url={url}
          />}
          <div className="post__footer">
            <IconButton aria-label="like" style={{
              color: "grey"
            }}
              onClick={() => active !== "like" ? dispatch({ type: HANDLE_LIKE }) : null}>
              <ThumbUpIcon /><span className="post__likes">{thumbups}</span>
            </IconButton>
            <IconButton aria-label="dislike" style={{
              color: "grey"
            }}
              onClick={() => active !== "dislike" ? dispatch({ type: HANDLE_DISLIKE }) : null}>
              <ThumbDownIcon /><span className="post__dislikes">{thumbdowns}</span>
            </IconButton>
            <IconButton aria-label="whatsapp" onClick={() => dispatch({ type: HANDLE_COMMENT })}>
              <QuestionAnswerIcon /><span className="post__comments">{comments}</span>
            </IconButton>
            <IconButton aria-label="download" onClick={() => isBlank(url) ? null : saveAs(url, postid.substring(0, postid.length - 10))}>
              <GetAppIcon />
            </IconButton>
          </div>
          <FlipMove>
            {toggleComment && posts.map((comment) => (
              <Post
                uid={uid}
                password={password}
                verified={verified}
                key={comment.id}
                postid={comment.id}
                message={comment.message}
                url={comment.url}
                parent={comment.parent}
                author={comment.author}
                created={comment.created}
                firstname={comment.firstname}
                lastname={comment.lastname}
                city={comment.city}
                standard={comment.standard}
                avatar={comment.avatar}
                subscribed={comment.subscribed === "True"}
                tlikes={comment.likes}
                tdislikes={comment.dislikes}
                tcomments={comment.comments}
                tdownloads={comment.downloads}
                mypost={comment.author === uid}
              />
            ))}
          </FlipMove>
          {verified && <div className="post__comment">
            <form style={formStyling} noValidate onSubmit={onSubmit}>
              <input
                style={BarStyling}
                key="comment"
                value={comment}
                placeholder={"Write a comment..."}
                onChange={(e) => setComment(e.target.value)}
              />
            </form>
          </div>}
        </div>
      </div>
    );
  }
);

export default Post;