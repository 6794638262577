import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Footer from "./Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import SignIn from './SignIn';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const [id, setId] = useState('')
  const [passcode, setPasscode] = useState('')
  const [password, setPassword] = useState('')
  const [repeatpassword, setRepeatpassword] = useState('')
  let history = useHistory();

  const location = useLocation();
  
  useEffect(() => {
    if(location.state !== undefined)
    {
     setId(location.state.id);
     setPasscode(location.state.passcode);
    }
  }, [location]);
  
  if (!id) {
    return <SignIn />
  }

  const changePassword = async (account) => {
    const res = await fetch('/api/v2/accounts/password/change/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(account),
    })
    const data = await res.json()
    if (res.status === 200) {
      alert(data["message"])
      history.push({
        pathname: "/accounts/login/"
      })
    }
  }
  const onSubmit = (e) => {
    e.preventDefault()

    if (!password) {
      alert('Please enter new password')
      return
    }
    if(password.length < 6){
      alert('Please enter minimum 6 digit password')
      return
    }
    if (!repeatpassword) {
      alert('Please repeat new password')
      return
    }
    if (password !== repeatpassword) {
      alert('Passwords do not match!')
      return
    }
    changePassword({ id, passcode, password })

    setPassword('')
    setRepeatpassword('')
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Just one more step
        </Typography>
        <br></br>
        <p>Enter the new password you want to keep for your account.</p>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="repeatpassword"
            label="Repeat Password"
            type="password"
            id="repeatpassword"
            autoComplete="current-password"
            value={repeatpassword}
            onChange={(e) => setRepeatpassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Change Password
          </Button>
        </form>
        <Footer/>
      </div>
    </Container>
  );
}