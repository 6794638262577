import React from "react"
import "./style.css";
import { Link } from 'react-router-dom'
export default function PaymentSuccess (){
  const queryParameters = new URLSearchParams(window.location.search)
  const extensioncode = queryParameters.get("exn_code")
    return (
      <div class="upper-part" align="center">
      <h2>Subscription renewed successfully!!!</h2>
      <small>You got unlimited access for next 3 months.</small>      
      <div class="price">
          <h3><i class="fa-solid fa-indian-rupee-sign"></i>Here is your extension code:</h3>
      </div>
      <p>{extensioncode}</p>
              <Link to="/accounts/login/" variant="body2">
             Sign in
              </Link>
              <br/>
              <p>Please keep note of above extension code and use while sign in.</p>
      </div>
    );
}