import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Footer from './Footer';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [phonenumber, setPhonenumber] = useState('')
  const [avatar, setAvatar] = useState("https://ik.imagekit.io/fickleisfun/profile.png")
  const [emailaddress, setEmailaddress] = useState('')
  const [standard, setStandard] = useState('')
  const [city, setCity] = useState('')

  let history = useHistory();

  const addAccount = async (account) => {
    
    const res = await fetch('/api/v2/accounts/signup/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },

      body: JSON.stringify(account),
    })
    const data = await res.json()
    
    if (res.status === 200) {
      //console.log(data["message"])
      history.push({
        pathname: "/accounts/activate/",
        state: { id: data["id"] }
      })
    }
  }
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  function validatePhone(phonenumber) {
    if (typeof phonenumber !== "undefined") {  
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(phonenumber)) {
        return false
      }else if(phonenumber.length !== 10){
        return false
      } else {
        return true
      }
    }
  }
  const onSubmit = (e) => {
    e.preventDefault()

    // VALIDATION CHECK FOR INPUT ITEMS 
    if (!firstname) {
      alert("Please enter your first name")
      return
    }
    if (!lastname) {
      alert("Please enter your last name")
      return
    }
    if (!phonenumber) {
      alert('Please enter 10 digit phone number')
      return
    }
    if (!validatePhone(phonenumber)) {
      alert('Please enter 10 digit phone number')
      return
    }
    if (!validateEmail(emailaddress)) {
      alert('Please enter a valid email id')
      return
    }
    if (!city) {
      alert('Please enter your city name')
      return
    }
    if (!standard) {
      alert("Please enter your standard")
      return
    }
    // CALL FOR ACTION BASED ON EVENT - <MenuItem value={"Teacher"}>TEACHER</MenuItem> 
    addAccount({ firstname, lastname, phonenumber, emailaddress, city, avatar, standard })
    setFirstname('')
    setLastname('')
    setPhonenumber('')
    setEmailaddress('')
    setCity('')
    setStandard('')
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="firstname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phonenumber"
                label="Phone Number"
                name="phonenumber"
                autoComplete="phone"
                value={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                id="emailaddress"
                label="Email Address"
                name="emailaddress"
                autoComplete="email"
                value={emailaddress}
                onChange={(e) => setEmailaddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="city"
                required
                label="Current City"
                name="city"
                autoComplete="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} variant="outlined">
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Standard/Class</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  variant="outlined"
                  fullWidth
                  id="standard"
                  required
                  label="Standard/ Class"
                  name="standard"
                  autoComplete="standard"
                  value={standard}
                  onChange={(e) => setStandard(e.target.value)}
                >
                  <MenuItem value={"i"}>CLASS-I</MenuItem>
                  <MenuItem value={"ii"}>CLASS-II</MenuItem>
                  <MenuItem value={"iii"}>CLASS-III</MenuItem>
                  <MenuItem value={"iv"}>CLASS-IV</MenuItem>
                  <MenuItem value={"v"}>CLASS-V</MenuItem>
                  <MenuItem value={"vi"}>CLASS-VI</MenuItem>
                  <MenuItem value={"vii"}>CLASS-VII</MenuItem>
                  <MenuItem value={"viii"}>CLASS-VIII</MenuItem>
                  <MenuItem value={"ix"}>CLASS-IX</MenuItem>
                  <MenuItem value={"x"}>CLASS-X</MenuItem>
                  <MenuItem value={"xi"}>CLASS-XI</MenuItem>
                  <MenuItem value={"xii"}>CLASS-XII</MenuItem>
                  <MenuItem value={"xiii"}>CLASS-XIII</MenuItem> 
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox disabled checked value="allowExtraEmails" color="primary" />}
                label="By signing up, you agree to our Terms , Data Policy and Cookies Policy."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/accounts/login/" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
        <Footer />
      </div>
    </Container>
  );
}