import { BrowserRouter as Router, Route } from 'react-router-dom'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import ForgotPassword from './components/ForgotPassword'
import ActivateAccount from './components/ActivateAccount'
import ChangePassword from './components/ChangePassword'
import Home from './components/Home'
import Explore from './components/Explore'
import Profile from './components/Profile'
import User from './components/User'
import Academic from './components/Academic'
import MasterClass from './components/MasterClass'
import LiveClass from './components/LiveClass'
import RenewSubscription from './components/RenewSubscription'
import MakePayment from './components/MakePayment'
import PaymentSuccess from './components/PaymentSuccess'
const App = () => {
    return (
    <Router>
      <div className='container'>
        <Route path='/' exact component={SignIn} />
        <Route path='/accounts/password/change/' component={ChangePassword} />
        <Route path='/accounts/password/reset/' component={ForgotPassword} />
        <Route path='/accounts/login/' component={SignIn} />
        <Route path='/accounts/activate/' component={ActivateAccount} />
        <Route path='/accounts/signup/' component={SignUp} />
        <Route path='/accounts/home/' component={Home} />
        <Route path='/accounts/explore/' component={Explore} />
        <Route path='/accounts/masterclass/' component={MasterClass} />
        <Route path='/accounts/academic/' component={Academic} />        
        <Route path='/accounts/profile/' component={Profile} />
        <Route path='/browse/profile/' component={User} />
        <Route path='/join/liveclass/' component={LiveClass} />
        <Route path='/accounts/renew/' component={RenewSubscription} />
        <Route path='/accounts/pay/' component={MakePayment} />
        <Route path='/payment/success/' component={PaymentSuccess} />
      </div>
    </Router>
  )
}
export default App