import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import "./style.css";
import { PaytmButton } from "./paytmButton";
import SignIn from './SignIn';

export default function MakePayment (){
  const location = useLocation();
    const [id, setId] = useState('')
    const [password, setPassword] = useState('')
    let extensioncode= id + new Date().getTime();
  
    useEffect(() => {
      if (location.state !== undefined) {
        setId(location.state.id)
        setPassword(location.state.password)
        extensioncode= id + new Date().getTime();
      }
    }, [location]);

    if (!id) {
      return <SignIn />
    }
  
    return (
      <div class="upper-part" align="center">
      <h2>Renew your subscription</h2>
      <small>Get unlimited access for next 3 months</small>
      <div class="discount">
          <small>&#8377; 1999</small>
          <span>SAVE 50%</span>
      </div>
      <div class="price">
          <h3><i class="fa-solid fa-indian-rupee-sign"></i><span>999.00</span>/3 months</h3>
      </div>

      <div>
      <PaytmButton id={id}
            password={password} 
            extensioncode={extensioncode}/>
    </div>
        
        <p>Live classes, class notes and recordings!!!</p>
                </div>
    );
}