import React, { useState } from "react";
import "./TweetBox.css";
import { Avatar, Button } from "@material-ui/core";
import Header from "./Header"
import Compressor from 'compressorjs';
import axios from 'axios';

import { useHistory } from 'react-router-dom';
function ChangeProfile({ id,
  password,
  firstname,
  lastname,
  city,
  standard,
  avatar,
  subscribed }) {
  const [newpassword, setNewpassword] = useState('');
  const [passcode, setPasscode] = useState('');
  function isImage(url) {
    const filename = url.toLowerCase();
    return (filename.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }
  const title="Hi "+firstname+"!";
  let history = useHistory();
  async function upload(e) {
    const maxSize = 10485760;
    //console.log(e.target.files[0].size);
    if (!e.target.files[0]) {
      alert('Please upload a non-empty file.');
      return;
    }
    if (!isImage(e.target.files[0].name) || e.target.files[0].size > maxSize) {
      alert('Please upload an image file of size less than 10 MB');
      return;
    }
    var file = e.target.files[0];
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 128,
      maxHeight: 128,
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('password', password);
        // The third parameter is required for server
        formData.append('file', result, result.name);
        // Send the compressed image file to server with XMLHttpRequest.
        axios.post('/api/v2/accounts/profile/upload/', formData).then(function (response) {
          //console.log(response);
          if (response.status === 200) {
            alert(response.data["message"])
          }
      })
      .catch(function (error) {
          console.log(error);
      });
    }
  });
  }
  const changePassword = async (account) => {
    const res = await fetch('/api/v2/accounts/password/change/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(account),
    })
    const data = await res.json()
    if (res.status === 200) {
      alert(data["message"])
      history.push({
        pathname: "/accounts/login/"
      })
    }
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (!newpassword) {
      alert('Please enter new password')
      return
    }
    if (!passcode) {
      alert('Please enter 6 digit passcode')
      return
    }
    // CALL FOR ACTION BASED ON EVENT
    const password = newpassword
    changePassword({ id, passcode, password })
    setNewpassword("");
    setPasscode("");
  };
  return (
    <div className="tweetBox">
      <Header id={id}
        password={password}
        firstname={firstname}
        lastname={lastname}
        city={city}
        standard={standard}
        avatar={avatar}
        subscribed={subscribed}
        />
          <h1>{title}</h1>
      <form>
        <div className="tweetBox__input">
          <Avatar src={avatar} />
          <input
            onChange={(e) => setNewpassword(e.target.value)}
            name="newpassword"
            label="New Password"
            type="password"
            id="newpassword"
            autoComplete="new-password"
            value={newpassword}
            placeholder="Enter new password"
          />
        </div>
        <div className="tweetBox__imageInput">
          <input
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
            name="passcode"
            label="passcode"
            type="password"
            id="text"
            className="tweetBox__imageInput"
            placeholder="Enter your passcode"
          />
        </div>
        <div className="tweetBox__imageInput">
          <input required type='file' onChange={e => upload(e)} />
        </div>
        <Button
          onClick={onSubmit}
          type="submit"
          className="tweetBox__tweetButton"
        >
          Update
        </Button>
      </form>
    </div>
  );
}

export default ChangeProfile;