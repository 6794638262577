import React, { forwardRef } from "react";
import { useState } from "react";
import { useReducer } from "react";
import "./Post.css";
import { Avatar } from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import { saveAs } from 'file-saver';
import { Document, Page, pdfjs } from "react-pdf";
import { ReactTinyLink } from 'react-tiny-link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ClearIcon from '@material-ui/icons/Clear';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { useHistory } from 'react-router-dom';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import BlockIcon from '@material-ui/icons/Block';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HANDLE_FOLLOW = Symbol("HANDLE_FOLLOW");
const HANDLE_UNFOLLOW = Symbol("HANDLE_UNFOLLOW");
const HANDLE_BLOCK = Symbol("HANDLE_BLOCK");
const width = window.screen.width * 0.68;

const Person = forwardRef(
  ({ uid, password, key, oth_id, firstname, lastname, city, standard, avatar, subscribed,myprofile,myclass }, ref) => {
    const initialState = {
      id: uid,
      password: password,
      other_id: oth_id
    };
    const name = firstname + " " + lastname;
    const std = standard == 'Teacher'? standard : standard.toUpperCase();
    let history = useHistory();
    const reducer = (state, action) => {
      const { id, mates, other_id } = state;
      const handleFollow = async (person) => {
        const data = new FormData();
        data.append('uid', person.uid);
        data.append('password', person.password);
        data.append('other_uid', person.oth_id);
        const res = await fetch('/api/v2/accounts/class/add/', {
          method: 'POST',
          //  headers: {
          //    'Content-type': 'application/json',
          //  },
          body: data,
        })
        const out = await res.json()

        if (res.status === 200) {
          alert(out["message"])
        }
      }
      const handleUnfollow = async (person) => {
        const data = new FormData();
        data.append('uid', person.uid);
        data.append('password', person.password);
        data.append('other_uid', person.oth_id);
        const res = await fetch('/api/v2/accounts/class/remove/', {
          method: 'POST',
          //  headers: {
          //    'Content-type': 'application/json',
          //  },
          body: data,
        })
        const out = await res.json()

        if (res.status === 200) {
          alert(out["message"])
        }
      }
      const handleBlock = async (person) => {
        const data = new FormData();
        data.append('uid', person.uid);
        data.append('password', person.password);
        data.append('other_uid', person.oth_id);
        const res = await fetch('/api/v2/accounts/class/block/', {
          method: 'POST',
          //  headers: {
          //    'Content-type': 'application/json',
          //  },
          body: data,
        })
        const out = await res.json()

        if (res.status === 200) {
          alert(out["message"])
        }
      }
      switch (action.type) {
        case HANDLE_FOLLOW:
          let followers=state.mates -1 +2;
          handleFollow({ uid, password, oth_id })
          return {
            ...state,
            id: state.id,
            mates: followers,
            other_id: state.other_id
          }
        case HANDLE_UNFOLLOW:
          let yourfollowers=state.mates + 1 - 2;
          handleUnfollow({ uid, password, oth_id })
          return {
            ...state,
            id: state.id,
            mates: yourfollowers,
            other_id: state.other_id
          }
        case HANDLE_BLOCK:
          let myfollowers = state.mates + 1 - 2;
          handleBlock({ uid, password, oth_id })
          return {
            ...state,
            id: state.id,
            mates: myfollowers,
            other_id: state.other_id
          }
        
        default:
          return state;
      }
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const { id, mates, other_id } = state;
    return (
      <div className="post" ref={ref}>
        <div className="post__avatar">
          <Avatar src={avatar} />
        </div>
        <div className="post__body">
          <div className="post__header">
            <div className="post__headerText">
              <h3>
                {name}
                <span className="post__headerSpecial">
                  {subscribed && <VerifiedUserIcon className="post__badge" />}
                  {!myprofile &&  <IconButton className="post__icon" aria-label="browse" onClick={() => dispatch({ type: HANDLE_BLOCK })}>
                  <BlockIcon />
                  </IconButton>}
                {!myprofile && myclass && <IconButton className="post__icon" aria-label="browse"  onClick={() => dispatch({ type: HANDLE_UNFOLLOW })}>
                  <ClearIcon />
                </IconButton>}
                 {!myprofile && <IconButton className="post__icon" aria-label="browse"  onClick={() => dispatch({ type: HANDLE_FOLLOW })} >
                  <HowToRegIcon />
                </IconButton>}
                </span>
                <div>
                <span className="post__headerSpecial">
                  {"Class "+std+", "+city} 
                </span>
               
                </div>
                
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Person;