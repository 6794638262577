import React, { useState, useEffect } from "react";
import Person from "./Person";
import "./Feed.css";
import FlipMove from "react-flip-move";

function Batchmates({ id, password, standard }) {
  const [batchmates, setBatchmates] = useState([]);
  const [searchtext, setSearchtext] = useState('');
  const BarStyling = { width: "100%", background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.87)", padding: "0.5rem", marginBottom: "1.5rem" };
  const batchname = "My Batch";
  const getallbatchmates = async (account) => {
    const res = await fetch('/api/v2/accounts/batch/listall/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(account),
    })
    const data = await res.json()
    
    if (res.status === 200) {
      setBatchmates(data["batchmates"])
      //console.log(data["batchmates"])
    }
  }

  useEffect(() => {
    getallbatchmates({ id, password })
  }, [id,password]);
  const onSubmit = (e) => {
    e.preventDefault()
    if (!searchtext) {
        getallbatchmates({ id, password })
      return
    }
    //const tags = searchtext.toLowerCase().split(" ")[0];
    getallbatchmates({ id, password })
    setSearchtext('')
  }
  return (
    <div className="feed">
      <form noValidate onSubmit={onSubmit}>
        <input
          style={BarStyling}
          key="searchtext"
          value={searchtext}
          placeholder={"search batchmates by name, phone or email address"}
          onChange={(e) => setSearchtext(e.target.value)}
        />
      </form>
      <h3>{batchname}</h3>
      <FlipMove>
        {batchmates.map((batchmate) => (
          <Person
            uid={id}
            password={password}
            key={batchmate.id}
            oth_id={batchmate.id}
            firstname={batchmate.firstname}
            lastname={batchmate.lastname}
            city={batchmate.city}
            standard={batchmate.standard}
            avatar={batchmate.avatar}
            subscribed={batchmate.subscribed === "True"}
            myprofile={id === batchmate.id}
            myclass= {false}
          />
        ))}
      </FlipMove>
    </div>
  );
}

export default Batchmates;