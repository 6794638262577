import React, { useState, useEffect } from "react";
import Person from "./Person";
import "./Feed.css";
import FlipMove from "react-flip-move";

function Academicians({ id, password, standard }) {
  const [academicians, setAcademicians] = useState([]);
  const [searchtext, setSearchtext] = useState('');
  const BarStyling = { width: "100%", background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.87)", padding: "0.5rem", marginBottom: "1.5rem" };
  const batchname = "Teachers & Academicians";
  const getallacademicians = async (account) => {
    const res = await fetch('/api/v2/accounts/academicians/listall/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(account),
    })
    const data = await res.json()
    
    if (res.status === 200) {
      setAcademicians(data["academicians"])
    }
  }

  useEffect(() => {
    getallacademicians({ id, password })
  }, [id,password]);
  const onSubmit = (e) => {
    e.preventDefault()
    if (!searchtext) {
        getallacademicians({ id, password })
      return
    }
    //const tags = searchtext.toLowerCase().split(" ")[0];
    getallacademicians({ id, password })
    setSearchtext('')
  }
  return (
    <div className="feed">
      <form noValidate onSubmit={onSubmit}>
        <input
          style={BarStyling}
          key="searchtext"
          value={searchtext}
          placeholder={"search academicians by name, phone or email address"}
          onChange={(e) => setSearchtext(e.target.value)}
        />
      </form>
      <h3>{batchname}</h3>
      <FlipMove>
        {academicians.map((academician) => (
          <Person
            uid={id}
            password={password}
            key={academician.id}
            oth_id={academician.id}
            firstname={academician.firstname}
            lastname={academician.lastname}
            city={academician.city}
            standard={academician.standard}
            avatar={academician.avatar}
            subscribed={academician.subscribed === "True"}
            myprofile={id === academician.id}
            myclass= {false}
          />
        ))}
      </FlipMove>
    </div>
  );
}

export default Academicians;